/* GlobalStyles.css */

/* Import the Roboto font from Google Fonts with font-display swap */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Reset margin, padding, and box-sizing */
* {
  margin: 10;
  padding: 10;
  box-sizing: border-box;
}

/* Body styles */
body {
  font-family: 'Roboto', sans-serif; /* Use Roboto as the primary font */
  background-color: #FFFFFF; /* White background */
  color: #333; /* Default text color */
  padding: 0 0px; /* Add padding to the left and right sides */
  margin: 0 auto; /* Keep existing styling */
  max-width: 1200px; /* Optional: set a max-width for wider screens */
}

/* Set some basic defaults for common elements */
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  color: #333; /* Default heading color */
}

p {
  line-height: 1.6; /* Improve readability of paragraphs */
}

a {
  color: #2196F3; /* Ocean Blue for links */
  text-decoration: none; /* No underline by default */
}

a:hover {
  text-decoration: underline; /* Underline on hover for links */
}

/* Utility classes for primary, secondary, and accent colors */
.primary-color {
  color: #4EA6D8;
}

.secondary-color {
  color: #83C769;
}

.accent-color {
  color: #EF893D;
}

/* Navigation Bar */
.nav-bar {
  background-color: #FFFFFF; /* Adjust the color as needed */
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}

.nav-logo img {
  height: 50px; /* Adjust the size as needed */
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links a {
  color: #333; /* Link color */
  margin-left: 20px;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
}

/* Hamburger Menu Icon */
.nav-menu-icon {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
}

.nav-menu-icon div {
  width: 25px;
  height: 3px;
  background-color: black;
}

/* Mobile view styles */
@media (max-width: 768px) {
  .nav-menu-icon {
    display: flex;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    width: 100%;
    border-top: 1px solid #ddd;
  }

  .nav-links.show {
    display: flex;
  }

  .nav-links a {
    margin: 10px 20px;
  }
}

/* Club listings style */
.club-listing h2 {
  margin-bottom: 5px; /* Adjust the gap below the heading */
}

.club-listing div {
  margin-top: 5px; /* Adjust the gap above the information divs */
}


.club-listing {
  padding: 5px;
  margin-bottom: -10px;
  border-bottom: 2px solid #ddd; /* Adds a line between listings */
}

.club-listing:last-child {
  border-bottom: none; /* Remove bottom border for the last item */
}

.club-links {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds space between icons */
}

.club-links img {
  width: 20px; /* Adjust the size of the icons */
}

.club-tags {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds space between tags */
}

/* Days of the week filter */
.days-filter {
  display: flex;
  flex-wrap: column;
  gap: 10px;
}

.days-filter label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.days-filter input[type="checkbox"] {
  margin-right: 5px;
}

.days-checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.day-checkbox {
  display: flex;
  align-items: center;
}

/* Terrains filter */
.terrains-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.terrains-filter label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.terrains-filter input[type="checkbox"] {
  margin-right: 5px;
}

.terrains-checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.terrain-checkbox {
  display: flex;
  align-items: center;
}

/*  friendly tag */
.beginner-friendly-tag {
  display: inline-block;
  background-color: #4EA6D8; /* Your primary color */
  color: white;
  padding: 5px;
  margin-top: 0px;
  border-radius: 5px;
  font-size: 0.8em;
}

/* Social tag */
.social-tag {
  display: inline-block;
  background-color: #83C769; /* Your secondary color */
  color: white;
  padding: 5px;
  margin-top: 0px;
  border-radius: 5px;
  font-size: 0.8em;
}

/* Very social tag */
.very-social-tag {
  display: inline-block;
  background-color: #EF893D; /* Your tertiary color */
  color: white;
  padding: 5px;
  margin-top: 0px;
  border-radius: 5px;
  font-size: 0.8em;
}
